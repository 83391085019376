<template>
  <el-scrollbar class="education-wrapper" :native="false">
    <div class="steps-wrapper">
      <div class="step-item" :class="{ current: [1, 2].includes(stepActive) }">
        <div class="step-line"></div>
        <div class="step-text">1</div>
        <div class="step-title">创建竞赛模板</div>
      </div>
      <div class="step-item" :class="{ current: [2].includes(stepActive) }">
        <div class="step-line"></div>
        <div class="step-text">2</div>
        <div class="step-title">{{ createMessage }}</div>
      </div>
    </div>
    <div class="create-content" v-if="stepActive === 1">
      <el-form
        :model="competitionForm"
        :rules="competitionRules"
        ref="competitionForm"
        label-width="120px"
      >
        <el-form-item label="竞赛名称" prop="tmpl_name">
          <el-input
            v-model="competitionForm.tmpl_name"
            placeholder="4-100个字"
            clearable
            style="width: 380px"
          ></el-input>
        </el-form-item>
        <el-form-item label="模板名称" prop="tmpl_name_alias">
          <el-input
            v-model="competitionForm.tmpl_name_alias"
            placeholder="4-100个字"
            clearable
            style="width: 380px"
          ></el-input>
        </el-form-item>
        <el-form-item label="店铺装修" prop="shop_decoration_id">
          <el-select
            v-model="competitionForm.shop_decoration_id"
            placeholder="请选择店铺装修"
            clearable
            style="width: 380px; margin-right: 10px"
            @clear="clearShop_decoration"
          >
            <el-option
              v-for="decorationItem in decorationList"
              :key="decorationItem.id"
              :label="decorationItem.d_t_name"
              :value="decorationItem.id"
            ></el-option>
          </el-select>
          <el-input
            v-model.number="competitionForm.shop_decoration_percent"
            oninput="value=value.replace(/[^\d]/g,'')"
            placeholder="设置分值比例"
            style="width: 120px; margin-right: 10px"
            @change="inputVal"
          ></el-input
          >%
        </el-form-item>
        <el-form-item label="客户服务" prop="kefu_id">
          <el-select
            v-model="competitionForm.kefu_id"
            placeholder="请选择客户服务"
            clearable
            style="width: 380px; margin-right: 10px"
            @clear="clearKefu"
          >
            <el-option
              v-for="kefuItem in kefuList"
              :key="kefuItem.id"
              :label="kefuItem.kf_name"
              :value="kefuItem.id"
            ></el-option>
          </el-select>
          <el-input
            v-model.number="competitionForm.kefu_percent"
            placeholder="设置分值比例"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 120px; margin-right: 10px"
            @change="inputVal"
          ></el-input
          >%
        </el-form-item>
        <el-form-item label="运营推广" prop="operation_id">
          <el-select
            v-model="competitionForm.operation_id"
            placeholder="请选择运营推广"
            clearable
            style="width: 380px; margin-right: 10px"
            @clear="clearOperation"
          >
            <el-option
              v-for="operationItem in operationList"
              :key="operationItem.id"
              :label="operationItem.name"
              :value="operationItem.id"
            ></el-option>
          </el-select>
          <el-input
            v-model.number="competitionForm.operation_percent"
            placeholder="设置分值比例"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 120px; margin-right: 10px"
            @change="inputVal"
          ></el-input
          >%
        </el-form-item>
        <el-form-item label="路演模块" prop="road_show">
          <el-input
            v-model.number="competitionForm.road_show_percent"
            placeholder="设置分值比例"
            oninput="value=value.replace(/[^\d]/g,'')"
            @change="inputVal"
            style="width: 120px; margin-right: 10px"
          ></el-input
          >%
        </el-form-item>

        <el-form-item label="理论考试" prop="sexercises_id">
          <el-cascader
            v-model="options"
            :options="sexercisesList"
            :props="keyObj"
            :show-all-levels="false"
            style="width: 380px; margin-right: 10px"
            @change="handleClick"
            ref="cascader"
          ></el-cascader>
          <el-input
            v-model.number="competitionForm.sexercises_percent"
            placeholder="设置分值比例"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 120px; margin-right: 10px"
            @change="inputVal"
          ></el-input
          >%
        </el-form-item>

        <el-form-item prop="testPaper_id">
          <el-select
            v-model="competitionForm.testPaper_id"
            placeholder="请选择试卷"
            clearable
            style="width: 380px; margin-right: 10px"
            @clear="clearTestPaper"
          >
            <el-option
              v-for="paperItem in paperList"
              :key="paperItem.paper_id"
              :label="paperItem.paper_name"
              :value="paperItem.paper_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据分析" prop="data_id">
          <el-select
            v-model="competitionForm.data_id"
            placeholder="请选择客户服务"
            clearable
            style="width: 380px; margin-right: 10px"
            @clear="clearData"
          >
            <el-option
              v-for="kefuItem in dataList"
              :key="kefuItem.data_template_id"
              :label="kefuItem.template_name"
              :value="kefuItem.data_template_id"
            ></el-option>
          </el-select>
          <el-input
            v-model.number="competitionForm.data_percent"
            placeholder="设置分值比例"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 120px; margin-right: 10px"
            @change="inputVal"
          ></el-input
          >%
        </el-form-item>
        <el-form-item label="客户服务(新)" prop="kefu_ids">
          <el-select
            v-model="competitionForm.kefu_new_id"
            placeholder="请选择客户服务"
            clearable
            style="width: 380px; margin-right: 10px"
            @clear="clearKefus"
          >
            <el-option
              v-for="kefuItem in kefuNewList"
              :key="kefuItem.id"
              :label="kefuItem.name"
              :value="kefuItem.id"
            ></el-option>
          </el-select>
          <el-input
            v-model.number="competitionForm.kefu_new_percent"
            placeholder="设置分值比例"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 120px; margin-right: 10px"
            @change="inputVal"
          ></el-input
          >%
        </el-form-item>
      </el-form>
    </div>
    <div class="create-content" v-if="stepActive === 2">
      <div class="success-content">
        <i class="iconfont">&#xe730;</i>
        <p class="text">{{ createMessage }}</p>
      </div>
    </div>
    <div class="bottom-btn">
      <el-button @click="prevBtn(stepActive)" v-if="stepActive === 1"
        >上一步</el-button
      >
      <el-button @click="prevBtn(stepActive)" v-if="stepActive === 2"
        >返回</el-button
      >
      <el-button
        class="btn-blue"
        @click="nextBtn('competitionForm')"
        v-if="stepActive === 1"
        >下一步</el-button
      >
    </div>
  </el-scrollbar>
</template>

<script>
import _ from "underscore";
import { theoryLibSelect, showPaperList,getCustomServiceList,getNewKeFuListData } from "@/utils/apis";
export default {
  name: "CreateCompetition",
  data() {
    return {
      //步骤
      stepActive: 1,
      createMessage: "创建成功",
      //第一步表单数据
      competitionForm: {
        tmpl_name: "",
        tmpl_name_alias:'',
        shop_decoration_id: "",
        shop_decoration_name: "",
        shop_decoration_percent: null,
        kefu_id: "",
        kefu_name: "",
        kefu_percent: null,
        operation_id: "",
        operation_name: "",
        operation_percent: null,
        sexercises_id: "",
        sexercises_name: "",
        sexercises_percent: null,
        testPaper_id: "",
        testPaper_name: "",
        road_show_percent: null,
        kefu_new_id: "",
        kefu_new_name: "",
        kefu_new_percent: null,
        data_id: "",
        data_name: "",
        data_percent: null,
      },
      competitionRules: {
        tmpl_name: [
          { required: true, message: "请输入竞赛名称", trigger: "blur" },
          {
            min: 4,
            max: 100,
            message: "长度在 4 到 100 个字",
            trigger: "blur",
          },
        ],
        tmpl_name_alias:[
        { required: true, message: "请输入模板名称", trigger: "blur" },
          {
            min: 4,
            max: 100,
            message: "长度在 4 到 100 个字",
            trigger: "blur",
          },
        ]
      },
      //模板内容
      tmpl_module: [],
      //题库列表
      sexercisesList: [],
      // 试卷分类项
      paperList: [],
      // 二级分类id
      categoryid: null,
      //店铺装修列表
      decorationList: [],
      //客户服务列表
      kefuList: [],
      dataList:[],
      kefuNewList:[],
      //运营推广列表
      operationList: [],
      //竞赛模板id
      competitionId: Number(this.$route.query.id) || 0,
      options: "",
      keyObj: {
        label: "name",
        value: "id",
        children: "children",
      },
    };
  },
  computed: {
    totalScore() {
      let shopDecorationScore =
        Number(this.competitionForm.shop_decoration_percent) || 0;
      let kefuScore = Number(this.competitionForm.kefu_percent) || 0;
      let DataScore=Number(this.competitionForm.data_percent)||0;
      let kefuNewScore=Number(this.competitionForm.kefu_new_percent)||0;
      let operationScore = Number(this.competitionForm.operation_percent) || 0;
      let roadShowScore = Number(this.competitionForm.road_show_percent) || 0;
      let sexercisesScore =
        Number(this.competitionForm.sexercises_percent) || 0;
      if (
        shopDecorationScore > -1 ||
        kefuScore > -1 ||
        operationScore > -1 ||
        roadShowScore > -1 ||
        sexercisesScore > -1||
        DataScore>-1||
        kefuNewScore>-1
      ) {
        return (
          shopDecorationScore +
          kefuScore +
          operationScore +
          roadShowScore +
          sexercisesScore+
          DataScore+
          kefuNewScore
        );
      } else {
        return 0;
      }
    },
  },
  created() {
    this.showSexercisesCategoryList();
  },
  mounted() {
    this.getShopDecorationList();
    this.getKefuList();
    this.getOperationList();
    this.editCompetition();
    this.getCustomServiceData();
    this.getNewKefuData();
  },
  methods: {
    // 获取试卷列表分类项
    handleClick(row) {
      this.categoryid = row[1];
      let params = {
        paging: 0,
        category_id: this.categoryid,
      };
      this.competitionForm.testPaper_id = "";
      this.paperList = [];
      this.$forceUpdate();
      showPaperList(params).then((res) => {
        this.paperList = res.data.list;
      });
    },

    // 获取题库分类
    showSexercisesCategoryList() {
      theoryLibSelect().then((res) => {
        this.sexercisesList = res.data;
      });
    },

    // 获取数据分析列表
    getCustomServiceData(){
      let params={
        paging:0
      }
      getCustomServiceList(params).then((res)=>{
        if(res.code===200){
          this.dataList=res.data.list;
        }
      })
    },

    // 获取新客户服务列表
    getNewKefuData(){
      let prams={
        page:0
      }
      getNewKeFuListData(prams).then((res)=>{
        if(res.code===200){
         this.kefuNewList=res.data.list
        }
      })
    },

    //设置分值比例必须大于0
    inputVal(val) {
      if (Number(val) === 0) {
        this.$message.warning("设置分值比例必须大于0！");
        return;
      }
    },
    //店铺装修模板列表
    getShopDecorationList() {
      this.$http.axiosGetBy(
        this.$api.decorateTemplateList,
        { type: "0" },
        (res) => {
          if (res.code === 200) {
            this.decorationList = res.data;
          } else {
            this.$message.warning(res.msg);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
    //客户服务模板列表
    getKefuList() {
      this.$http.axiosGetBy(
        this.$api.showKfList,
        { type: "all" },
        (res) => {
          if (res.code === 200) {
            this.kefuList = res.data;
          } else {
            this.$message.warning(res.msg);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
    //运营推广模板列表
    getOperationList() {
      let param = {
        type: "all",
      };
      this.$http.axiosGetBy(
        this.$api.showOperationList,
        param,
        (res) => {
          if (res.code === 200) {
            this.operationList = res.data;
          } else {
            this.$message.warning(res.msg);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },

    getParent(id) {
      this.sexercisesList.forEach((item) => {
        item.children.forEach((subItem) => {
          if (subItem.id == id.child.id) {
            this.options = [item.id, id.child.id];
            let params = {
              paging: 0,
              category_id: id.child.id,
            };
            showPaperList(params).then((res) => {
              this.paperList = res.data.list;
            });
          }
        });
      });
    },

    //编辑
    editCompetition() {
      if (this.competitionId) {
        this.$http.axiosGetBy(
          this.$api.templateList,
          { id: this.competitionId },
          (res) => {
            if (res.code === 200) {
              this.competitionForm.tmpl_name = res.data.tmpl_name;
              this.competitionForm.tmpl_name_alias=res.data.tmpl_name_alias;
              for (let i = 0; i < res.data.expand.length; i++) {
                // //理论
                if (parseInt(res.data.expand[i].tmpl_module_type) === 5) {
                  this.competitionForm.testPaper_id =
                    res.data.expand[i].tmpl_module_id;
                  this.competitionForm.sexercises_percent =
                    res.data.expand[i].tmpl_module_percent;
                    setTimeout(() => {
                      this.getParent(res.data.expand[i].first);
                    }, 1000);
                }
                //装修
                if (parseInt(res.data.expand[i].tmpl_module_type) === 1) {
                  this.competitionForm.shop_decoration_id =
                    res.data.expand[i].tmpl_module_id;
                  this.competitionForm.shop_decoration_percent =
                    res.data.expand[i].tmpl_module_percent;
                }
                //运营
                if (parseInt(res.data.expand[i].tmpl_module_type) === 2) {
                  this.competitionForm.operation_id =
                    res.data.expand[i].tmpl_module_id;
                  this.competitionForm.operation_percent =
                    res.data.expand[i].tmpl_module_percent;
                }
                //客服
                if (parseInt(res.data.expand[i].tmpl_module_type) === 3) {
                  this.competitionForm.kefu_id =
                    res.data.expand[i].tmpl_module_id;
                  this.competitionForm.kefu_percent =
                    res.data.expand[i].tmpl_module_percent;
                }
                //路演
                if (parseInt(res.data.expand[i].tmpl_module_type) === 4) {
                  this.competitionForm.road_show_percent =
                    res.data.expand[i].tmpl_module_percent;
                }
                
                // 数据分析
                if (parseInt(res.data.expand[i].tmpl_module_type) === 6) {
                  this.competitionForm.data_id =
                    res.data.expand[i].tmpl_module_id;
                  this.competitionForm.data_percent =
                    res.data.expand[i].tmpl_module_percent;
                }
                 // 新客服
                if (parseInt(res.data.expand[i].tmpl_module_type) === 7) {
                  this.competitionForm.kefu_new_id =
                    res.data.expand[i].tmpl_module_id;
                  this.competitionForm.kefu_new_percent =
                    res.data.expand[i].tmpl_module_percent;
                }
              }
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    },
    
    clearShop_decoration() {
      this.competitionForm.shop_decoration_id = "";
      this.competitionForm.shop_decoration_percent = null;
    },
    clearKefu() {
      this.competitionForm.kefu_id = "";
      this.competitionForm.kefu_percent = null;
    },
    clearKefus() {
      this.competitionForm.kefu_new_id = "";
      this.competitionForm.kefu_new_percent = null;
    },
    clearData() {
      this.competitionForm.data_id = "";
      this.competitionForm.data_percent = null;
    },
    clearOperation() {
      this.competitionForm.operation_id = "";
      this.competitionForm.operation_percent = null;
    },
    // clearSexercises() {
    //   // this.competitionForm.sexercises_id = "";
    //   // this.competitionForm.sexercises_percent = null;
    //   // this.competitionForm.testPaper_id = "";
    //   // this.paperList=null
    //   alert(1)
    // },
    clearTestPaper() {
      this.categoryid = null;
      this.competitionForm.testPaper_id = "";
      this.competitionForm.sexercises_percent = null;
      this.$refs.cascader.$refs.panel.clearCheckedNodes();
      this.paperList = [];
      this.$forceUpdate();
    },

    // 上一步
    prevBtn(index) {
      if (index === 1) {
        this.$router.go(-1);
      } else {
        this.$router.push("/admin/competition");
      }
    },
    //下一步
    nextBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //获取模板名称
          if (this.competitionForm.shop_decoration_id) {
            let shopDecorationName = _.find(this.decorationList, {
              id: this.competitionForm.shop_decoration_id,
            });
            this.competitionForm.shop_decoration_name =
              shopDecorationName.d_t_name;
            if (
              this.competitionForm.shop_decoration_percent === 0 ||
              this.competitionForm.shop_decoration_percent === null
            ) {
              this.$message.warning("店铺装修的设置分值比例必须大于0！");
              return;
            }
          }
          if (this.competitionForm.kefu_id) {
            let kefuName = _.find(this.kefuList, {
              id: this.competitionForm.kefu_id,
            });
            this.competitionForm.kefu_name = kefuName.kf_name;
            if (
              this.competitionForm.kefu_percent === 0 ||
              this.competitionForm.kefu_percent === null
            ) {
              this.$message.warning("客户服务的设置分值比例必须大于0！");
              return;
            }
          }
          if (this.competitionForm.kefu_new_id) {
            let kefuName = _.find(this.kefuNewList, {
              id: this.competitionForm.kefu_new_id,
            });
            this.competitionForm.kefu_new_name = kefuName.name;
            if (
              this.competitionForm.kefu_new_percent === 0 ||
              this.competitionForm.kefu_new_percent === null
            ) {
              this.$message.warning("新客户服务的设置分值比例必须大于0！");
              return;
            }
          }
          if (this.competitionForm.data_id) {
            let dataName = _.find(this.dataList, {
              data_template_id: this.competitionForm.data_id,
            });
            this.competitionForm.data_name = dataName.template_name;
            if (
              this.competitionForm.data_percent === 0 ||
              this.competitionForm.data_percent === null
            ) {
              this.$message.warning("数据分析的设置分值比例必须大于0！");
              return;
            }
          }
          if (this.competitionForm.operation_id) {
            let operationName = _.find(this.operationList, {
              id: this.competitionForm.operation_id,
            });
            this.competitionForm.operation_name = operationName.name;
            if (
              this.competitionForm.operation_percent === 0 ||
              this.competitionForm.operation_percent === null
            ) {
              this.$message.warning("运营推广的设置分值比例必须大于0！");
              return;
            }
          }
          // if (this.competitionForm.sexercises_id) {
          //   let sexercisesName = _.find(this.sexercisesList, {
          //     id: this.competitionForm.sexercises_id,
          //   });
          //   this.competitionForm.sexercises_name = sexercisesName.name;
          //   if (
          //     this.competitionForm.sexercises_percent === 0 ||
          //     this.competitionForm.sexercises_percent === null
          //   ) {
          //     this.$message.warning("理论考试的设置分值比例必须大于0！");
          //   }
          // }
          if (this.competitionForm.testPaper_id) {
            let testpaperName = _.find(this.paperList, {
              paper_id: Number(this.competitionForm.testPaper_id),
            });
            this.competitionForm.testPaper_name = testpaperName.paper_name;
            if (
              this.competitionForm.sexercises_percent === 0 ||
              this.competitionForm.sexercises_percent === null
            ) {
              this.$message.warning("理论考试的设置分值比例必须大于0！");
            }
          }

          if (
            this.competitionForm.shop_decoration_id ||
            this.competitionForm.kefu_id ||
            this.competitionForm.operation_id ||
            this.competitionForm.road_show_percent ||
            this.competitionForm.testPaper_id ||
            this.competitionForm.data_id ||
            this.competitionForm.kefu_new_id
          ) {
          } else {
            this.$message.warning("请选择至少一个模块进行考试");
            return;
          }
          if (
            this.competitionForm.shop_decoration_percent ||
            this.competitionForm.kefu_percent ||
            this.competitionForm.operation_percent ||
            this.competitionForm.road_show_percent ||
            this.competitionForm.sexercises_percent ||
            this.competitionForm.data_percent ||
            this.competitionForm.kefu_new_percent
          ) {
            if (
              this.competitionForm.shop_decoration_percent === 0 ||
              this.competitionForm.kefu_percent === 0 ||
              this.competitionForm.operation_percent === 0 ||
              this.competitionForm.road_show_percent === 0 ||
              this.competitionForm.sexercises_percent === 0 ||
              this.competitionForm.data_percent===0 ||
              this.competitionForm.kefu_new_percent===0
            ) {
              this.$message.warning("设置分值比例必须大于0！");
              return;
            }
            if (this.totalScore !== 100&&this.totalScore>100) {
              this.$message.warning("总分值比例是100%");
              return;
            } else {
              let formData = new FormData();
              formData.append("tmpl_name", this.competitionForm.tmpl_name);
              formData.append("tmpl_name_alias", this.competitionForm.tmpl_name_alias);
              //获取模板内容
              let tmplModule_decoration = {};
              let tmplModule_operation = {};
              let tmplModule_kefu = {};
              let tmplModule_kefuNew = {};
              let tmplModule_data = {};
              let tmplModule_road_show = {};
              // let tmplModule_sexercises = {};
              let tmplModule_testpaper = {};
              //装修
              if (this.competitionForm.shop_decoration_id) {
                tmplModule_decoration.tmpl_module_id =
                  this.competitionForm.shop_decoration_id;
                tmplModule_decoration.tmpl_module_type = 1;
                tmplModule_decoration.tmpl_module_name =
                  this.competitionForm.shop_decoration_name;
                tmplModule_decoration.tmpl_module_percent =
                  this.competitionForm.shop_decoration_percent;
              }
              //运营
              if (this.competitionForm.operation_id) {
                tmplModule_operation.tmpl_module_id =
                  this.competitionForm.operation_id;
                tmplModule_operation.tmpl_module_type = 2;
                tmplModule_operation.tmpl_module_name =
                  this.competitionForm.operation_name;
                tmplModule_operation.tmpl_module_percent =
                  this.competitionForm.operation_percent;
              }
              //客服
              if (this.competitionForm.kefu_id) {
                tmplModule_kefu.tmpl_module_id = this.competitionForm.kefu_id;
                tmplModule_kefu.tmpl_module_type = 3;
                tmplModule_kefu.tmpl_module_name =
                  this.competitionForm.kefu_name;
                tmplModule_kefu.tmpl_module_percent =
                  this.competitionForm.kefu_percent;
              }
              // 新客服
              if(this.competitionForm.kefu_new_id){
                tmplModule_kefuNew.tmpl_module_id=this.competitionForm.kefu_new_id;
                tmplModule_kefuNew.tmpl_module_type=7;
                tmplModule_kefuNew.tmpl_module_name=this.competitionForm.kefu_new_name;
                tmplModule_kefuNew.tmpl_module_percent=this.competitionForm.kefu_new_percent
              }
              // 数据分析
              if (this.competitionForm.data_id) {
                tmplModule_data.tmpl_module_id = this.competitionForm.data_id;
                tmplModule_data.tmpl_module_type = 6;
                tmplModule_data.tmpl_module_name =
                  this.competitionForm.data_name;
                tmplModule_data.tmpl_module_percent =
                  this.competitionForm.data_percent;
              }
              //路演
              if (this.competitionForm.road_show_percent) {
                tmplModule_road_show.tmpl_module_id = "";
                tmplModule_road_show.tmpl_module_type = 4;
                tmplModule_road_show.tmpl_module_name = "路演";
                tmplModule_road_show.tmpl_module_percent =
                  this.competitionForm.road_show_percent;
              }
              // //题库
              // if (this.competitionForm.sexercises_id) {
              //   tmplModule_sexercises.tmpl_module_id =
              //     this.competitionForm.sexercises_id;
              //   tmplModule_sexercises.tmpl_module_type = 5;
              //   tmplModule_sexercises.tmpl_module_name =
              //     this.competitionForm.sexercises_name;
              //   tmplModule_sexercises.tmpl_module_percent =
              //     this.competitionForm.sexercises_percent;
              // }
              //试卷
              if (this.competitionForm.testPaper_id) {
                tmplModule_testpaper.tmpl_module_id =
                  this.competitionForm.testPaper_id;
                tmplModule_testpaper.tmpl_module_type = 5;
                tmplModule_testpaper.tmpl_module_name =
                  this.competitionForm.testPaper_name;
                tmplModule_testpaper.tmpl_module_percent =
                  this.competitionForm.sexercises_percent;
              }
              this.tmpl_module=[]
              if (tmplModule_decoration.tmpl_module_percent > 0) {
                this.tmpl_module.push(tmplModule_decoration);
              }
              if (tmplModule_operation.tmpl_module_percent > 0) {
                this.tmpl_module.push(tmplModule_operation);
              }
              if (tmplModule_kefu.tmpl_module_percent > 0) {
                this.tmpl_module.push(tmplModule_kefu);
              }
              if (tmplModule_kefuNew.tmpl_module_percent > 0) {
                this.tmpl_module.push(tmplModule_kefuNew);
              }
              if (tmplModule_data.tmpl_module_percent > 0) {
                this.tmpl_module.push(tmplModule_data);
              }
              if (tmplModule_road_show.tmpl_module_percent > 0) {
                this.tmpl_module.push(tmplModule_road_show);
              }
              if (tmplModule_testpaper.tmpl_module_percent > 0) {
                // this.tmpl_module.push(tmplModule_sexercises);
                this.tmpl_module.push(tmplModule_testpaper);
              }

              formData.append("tmpl_module", JSON.stringify(this.tmpl_module));

              if (this.competitionId) {
                formData.append("id", this.competitionId);
                this.$http.axiosPost(
                  this.$api.templateUpdate,
                  formData,
                  (res) => {
                    if (res.code === 200) {
                      this.stepActive = 2;
                      this.createMessage = "编辑成功！";
                    } else {
                      this.$message.warning(res.msg);
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              } else {
                this.$http.axiosPost(
                  this.$api.templateAdd,
                  formData,
                  (res) => {
                    if (res.code === 200) {
                      this.stepActive = 2;
                      this.createMessage = "创建成功！";
                    } else {
                      this.$message.warning(res.msg);
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              }
            }
          } else {
            this.$message.warning(
              "请在相应的模块设置分值比例，总分值比例是100%"
            );
            return;
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.education-wrapper {
  height: 100%;
  /deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 60px;
    }
  }
}
.create-content {
  margin: 60px 0;
}
.bottom-btn {
  text-align: center;
}
.horizontal-form-item {
  display: flex;
  /deep/ .el-form-item__content {
    margin-left: 20px;
  }
}

.first-title {
  font-size: 18px;
  margin-bottom: 22px;
}
.success-content {
  margin-top: 155px;
  text-align: center;
  .iconfont {
    color: #1fd18c;
    font-size: 52px;
  }
  .text {
    font-size: 24px;
    margin: 20px 0 0;
    font-weight: bold;
  }
}
</style>